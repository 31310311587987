/* eslint-disable @typescript-eslint/no-untyped-public-signature */
import React from 'react';
import {ProductPlaceholder as ProductPlaceholderIcon} from '../../../../icons/dist';
import s from './ProductPlaceholder.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import {ISliderGlobalProps} from '../../../sliderGlobalStrategy';

@withGlobals
@withTranslations()
export class ProductPlaceholder extends React.Component<ISliderGlobalProps & IProvidedTranslationProps> {
  public render() {
    return (
      <div className={s.root} data-hook="product-placeholder">
        <div>
          <ProductPlaceholderIcon size="70px" data-hook="product-placeholder-image" />
        </div>
        <p className={s.text} data-hook="product-placeholder-text">
          {this.props.t('sliderGalleryNoProductsMessageText')}
        </p>
      </div>
    );
  }
}
